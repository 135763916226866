.react-datepicker { 
  width: 1008px;
  min-height: 270px;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  flex-direction: row;
  display: flex;
}
.react-datepicker__triangle {
  display: none;
}
.react-datepicker__day-name {
  margin: 0;
  color: #757575;
  padding: 14px 0;
  text-align: center;
  font-size: 13px;
  font-weight: 600;
  height: 48px;
  box-sizing: border-box;
  width: 50px;
}
.react-datepicker__current-month {
  font-family: NanumSquareEB;
  font-size: 18px;
  letter-spacing: 0.17px;
  color: black;
}

.react-datepicker__month-container {
  width: 350px;
  height: 270px;
  padding: 0 15px;
}

.react-datepicker__month-container {
  margin-left: 72px;
}

.react-datepicker__month-container:last-child {
  margin-left: 104px;
}

.react-datepicker__month {
  font-size: 16px;
  margin: 0;
}
.react-datepicker__week:first-child {
  display: flex;
  justify-content: flex-end;
}
.react-datepicker__week:first-child .react-datepicker__day {
  margin: 0;
}
.react-datepicker__week:last-child {
  display: flex;
  justify-content: flex-start;
}
.react-datepicker__week:last-child .react-datepicker__day {
  margin: 0;
}
.react-datepicker__week {
  display: flex;
  margin: 4px 0;
}
.react-datepicker__day--outside-month {
  visibility: hidden !important;
  display: none;
}
.react-datepicker__day:hover {
  border-radius: 50%;
}
.react-datepicker__day {
  width: 50px;
  height: 30px;
  margin: 0 auto;
  line-height: 30px;
  border-radius: 20px;
  transition: 0.3s all;
  color: #202020;
  font-size: 14px;
  font-family: NanumSquareEB;
  text-decoration: none;
}
.react-datepicker__day--disabled {
  color: #dbdbdb;
}
.react-datepicker__day--in-range, .react-datepicker__day--in-selecting-range {
  background-color: rgba(0, 176, 116, 0.3);
  color: #00b074;
  border-radius: 0;
}
/* .react-datepicker__day--selected, .react-datepicker__day--keyboard-selected, .react-datepicker__day--keyboard-selected:hover, .react-datepicker__day--in-selecting-range:hover {
  color: white;
  border: 1px solid #00b074;
  background-color: #00b074;
  border-radius: 15px;
  background-position: center;
  background-size: 30px 30px;
} */
.react-datepicker__day--selected, .react-datepicker__day--selected:hover {
  width: 48px;
  height: 28px;
  color: black;
  border: 2px solid #db5f33;
  background-color: transparent;
  border-radius: 0;
  background-position: center;
  background-size: 30px 30px;
  pointer-events: none;
}

.react-datepicker__day--range-start, .react-datepicker__day--range-end {
  color: white;
  border: 1px solid #00b074;
  background-color: #00b074;
  border-radius: 15px;
  background-position: center;
  background-size: 30px 30px;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--keyboard-selected:hover, .react-datepicker__day--in-range:hover {
  background-color: #ececec;
  border: 1px solid #00b074;
  color: #00b074;
}

.react-datepicker__header {
  background-color: white;
  margin-top: 8px;
}
.react-datepicker__month-select{
  margin-right: 22px;
  width: 114px;
  height: 32px;
  padding: 0 12px;
  font-size: 20px;
  font-weight: 600;
  color: #000;
  border: none;
  letter-spacing: 1.2px;
  background: url(../images/ic-date-pick.png) no-repeat right 5px top 50%;
  background-size: 24px 24px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
}
.react-datepicker__year-select{
  width: 114px;
  height: 32px;
  padding: 0 12px;
  font-size: 20px;
  font-weight: 600;
  color: #000;
  border: none;
  letter-spacing: 1.2px;
  background: url(../images/ic-date-pick.png) no-repeat right 5px top 50%;
  background-size: 24px 24px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
}
.react-datepicker__header__dropdown {
  margin-bottom: 4px;
}

.react-datepicker__navigation--next {
  top: 123px;
  right: 0px;
  width: 24px !important;
  height: 24px !important;
  background: url(../images/ic-cal-arrow-right.png) no-repeat;
  background-size: 12px 24px;
  padding: 20px;
  background-position: center;
}
.react-datepicker__navigation--previous {
  top: 123px;
  left: 12px;
  width: 24px !important;
  height: 24px !important;
  background: url(../images/ic-cal-arrow-left.png) no-repeat;
  background-size: 12px 24px;
  padding: 20px;
  background-position: center;
}
.react-datepicker__navigation:hover {
  border-radius: 24px;
  background-color: #ECEFF1;
}
.react-datepicker__navigation--next,
.react-datepicker__navigation--next:hover,
.react-datepicker__navigation--previous,
.react-datepicker__navigation--previous:hover {
  border: none;
}
input[type="date"]::-webkit-calendar-picker-indicator,
input[type="date"]::-webkit-calendar-clear-butotn,
input[type="date"]::-webkit-inner-spin-button{
    display: none;
}

