/* .pop-wrap.large .react-datepicker__month-container {
  width: 350px;
  height: 300px;
  padding: 0 15px;
  margin-left: 45px;
}

.pop-wrap.large .react-datepicker__month-container:last-child {
  margin-left: 72px;
}

.pop-wrap.large .react-datepicker__navigation--next, .pop-wrap.large .react-datepicker__navigation--previous {
  display: none;
}

.pop-wrap.large .react-datepicker__week:first-child .react-datepicker__day {
  margin-top: 4px;
}

.pop-wrap.large .react-datepicker__day {
  pointer-events: none;
}

.pop-wrap.large .react-datepicker__day--highlighted, .pop-wrap.large .react-datepicker__day--keyboard-selected, .pop-wrap.large .react-datepicker__day--keyboard-selected:hover {
  color: white;
  border: 1px solid #00b074;
  background-color: #00b074;
  border-radius: 15px;
  background-position: center;
  background-size: 30px 30px;
}
.pop-wrap.large .react-datepicker__day--highlighted:hover, .pop-wrap.large .react-datepicker__day--keyboard-selected:hover {
  color:  #00b074;
  border: 1px solid #00b074;
  background-color: white;
  border-radius: 15px;
  background-position: center;
  background-size: 30px 30px;
}

.pop-wrap.large .react-datepicker__day--selected, .pop-wrap.large .react-datepicker__day--keyboard-selected, .pop-wrap.large .react-datepicker__day--keyboard-selected:hover, .pop-wrap.large .react-datepicker__day--in-selecting-range:hover {
  color: black;
  border: 2px solid #db5f33;
  background-color: transparent;
  border-radius: 0;
  background-position: center;
  background-size: 30px 30px;
}
.pop-wrap.large .react-datepicker__day--selected:hover, .pop-wrap.large .react-datepicker__day--keyboard-selected:hover {
  background-color: transparent;
  border: 2px solid #db5f33;
  color: black;
} */

.legends {
  display: flex;
  line-height: 16px;
  font-family: "NanumSquareB";
  font-size: 12px;
}

.legends div {
  margin-left: 4px;
}

.legends .upload {
  width: 16px;
  height: 16px;
  background-color: #00b074;
  border-radius: 8px;
  margin-right: 20px;
}

.legends .today {
  width: 12px;
  height: 12px;
  border: 2px solid #db5f33;
}
