@charset "utf-8";

@font-face {
  font-family: "Nanum Gothic";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/NanumGothic-Regular.eot");
  src: url("../fonts/NanumGothic-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/NanumGothic-Regular.woff2") format("woff2"),
    url("../fonts/NanumGothic-Regular.woff") format("woff"),
    url("../fonts/NanumGothic-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Nanum Gothic";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/NanumGothic-Bold.eot");
  src: url("../fonts/NanumGothic-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/NanumGothic-Bold.woff2") format("woff2"),
    url("../fonts/NanumGothic-Bold.woff") format("woff"),
    url("../fonts/NanumGothic-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Nanum Gothic";
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/NanumGothic-ExtraBold.eot");
  src: url("../fonts/NanumGothic-ExtraBold.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/NanumGothic-ExtraBold.woff2") format("woff2"),
    url("../fonts/NanumGothic-ExtraBold.woff") format("woff"),
    url("../fonts/NanumGothic-ExtraBold.ttf") format("truetype");
}

/* default */
body {
  font-family: "맑은 고딕", "malgun gothic", sans-serif;
  line-height: 1.3;
  background: #f2f2f2;
  overflow-x: hidden;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
form,
fieldset,
p,
button,
iframe,
th,
td {
  margin: 0;
  padding: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
summary {
  display: block;
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
dt {
  font-weight: normal;
}
img,
fieldset,
iframe,
button {
  border: 0 none;
  vertical-align: top;
}
li {
  list-style: none;
}
table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  table-layout: fixed;
}
legend,
caption,
hr {
  display: none;
}
em,
u,
cite {
  font-style: normal;
}
a {
  color: #202020;
  text-decoration: none;
  outline-offset: -1px;
}
button {
  border: 0 none;
  font-family: inherit;
  cursor: pointer;
  font-size: inherit;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
}
.chrome a,
.safari a {
  outline-offset: -3px;
}
html {
  font-size: 82.5%;
}
body,
html {
  width: 100%;
  height: 100%;
  font-size: 14px;
  color: #202020;
  box-sizing: border-box;
  font-family: "Nanum Gothic";
  font-weight: 400;
}
body:before {
  content: "";
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 0;
} /* fix webkit bug(scroll event delay) */
.hide {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  color: transparent !important;
  overflow: hidden !important;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
}

select {
  font-size: inherit;
  font-family: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #b3b3b3;
}
::-webkit-input-placeholder,
::moz-placeholder,
:-ms-input-placeholder {
  font-size: inherit;
  font-family: "NanumSquare";
}
select::-ms-expand {
  display: none;
}
select {
  display: inline-block;
  position: relative;
  vertical-align: top;
  width: 100%;
  height: 40px;
  padding: 0 25px 0 20px;
  background: #fff url(../images/ic-input-select.png) no-repeat 100% 7px;
  border: 1px solid #dbdbdb;
  cursor: pointer;
}
select option {
  font-style: normal;
  background-color: #fff;
}
select:focus {
  border-color: #00b074;
  outline: 0;
}
select:required:invalid {
  color: #b3b3b3;
}

.hidden {
  visibility: hidden;
  padding-right: 10px;
}
.select {
  font-family: "NanumSquare";
  cursor: pointer;
  display: inline-block;
  position: relative;
  width: 100%;
  height: 40px;
  box-sizing: border-box;
}
.cusSelect {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  padding: 0 40px 0 20px;
  border: 1px solid #dbdbdb;
  line-height: 38px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.cusSelect:after {
  content: "";
  width: 36px;
  height: 24px;
  position: absolute;
  top: 7px;
  right: 0;
  background: url(../images/ic-input-select.png) no-repeat;
}
/* .cusSelect:active, .cusSelect.active {border-color:#00b074; } */
.cusSelect:active,
.cusSelect.active {
  border-color: #00b074;
  outline: 0;
}
.cusSelect.active:after {
  top: 6px;
  right: 0;
  background: url(../images/ic-input-close.png) no-repeat;
}

.campaign-title .cusSelect {
  border: none;
  /* border-bottom: 1px solid #dbdbdb; */
  padding-left: 0;
  font-size: x-large;
  font-weight: bold;
  width: 50%;
}

.campaign-title .options {
  width: 50%;
  border-top: 1px solid #dbdbdb;
}

.report-influencers.select {
  margin-top: 53px;
}

.options {
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 999;
  margin: 0;
  padding: 0 0;
  list-style: none;
  border: 1px solid #dbdbdb;
  border-top: 0;
  background: #fff;
  max-height: 250px;
  overflow-y: auto;
}
.options .hide {
  display: none;
}
.options li {
  padding: 0 20px;
  line-height: 40px;
}
.options li:hover {
  background-color: rgba(0, 176, 116, 0.1);
}

.heading-box {
  position: relative;
  margin: 63px 0 20px 0;
}
.heading-box .btn-cont {
  position: absolute;
  top: -7px;
  right: 0;
}
h2 {
  font-size: 24px;
  font-weight: 700;
  color: #3e3e3e;
  letter-spacing: -0.16px;
}

/* button */
input[type="button"] {
  border: 0;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
}
[class^="btn-"] {
  display: inline-block;
  font-size: 16px;
  letter-spacing: 0.25px;
  text-align: center;
  font-family: "NanumSquare";
  font-weight: 400;
  border-radius: 4px;
}
[class^="btn-"]:focus,
div:focus {
  outline: none;
}
.btn-line {
  width: 200px;
  height: 64px;
  line-height: 64px;
  border: solid 1px #9b9b9b;
  border-radius: 2px;
  padding: 0 15px;
  background: #fff;
}
.btn-line.middle {
  width: 160px;
  height: 50px;
  line-height: 46px;
  border: solid 2px #00b074;
  color: #00b074;
  font-weight: 700;
}
.btn-line.mid {
  width: 122px;
  height: 38px;
  line-height: 34px !important;
  border: solid 2px #00b074;
  color: #00b074;
  font-weight: 700;
}
.btn-line.small,
.btn-cont.small {
  height: 46px;
  width: 114px;
  font-size: 14px;
  line-height: 46px;
  border-radius: 2px;
}
.btn-line.xsmall {
  width: 64px;
  height: 26px;
  font-size: 12px;
  line-height: 24px;
  border: solid 2px #00b074;
  color: #00b074;
  padding: 0;
  font-weight: 700;
}
.btn-cont {
  width: 200px;
  height: 64px;
  line-height: 64px;
  background: #00b074;
  color: #fff;
}
.btn-cont.middle {
  width: 160px;
  height: 50px;
  line-height: 50px;
}
.btn-cont.xsmall,
.btn-sub.xsmall {
  width: 64px;
  height: 26px;
  font-size: 12px;
  line-height: 28px;
}
.btn-cont.xsmall:disabled,
.btn-sub.xsmall:disabled {
  background: #9b9b9b;
  color: #fff;
  cursor: default;
}
.btn-sub {
  width: 200px;
  height: 64px;
  line-height: 64px;
  background: #ec541e;
  color: #fff;
}
/* {width:auto; height:38px; line-height:38px; padding:0 16px;} */
.btn-cont:disabled {
  color: #f5f5f5;
  background-color: #9b9b9b;
  cursor: default;
}
[class^="btn-"].mid {
  width: 122px;
  height: 38px;
  line-height: 38px;
}
.btn-tooltip {
  display: inline-block;
  width: 24px;
  height: 24px;
  overflow: hidden;
  font-size: 1px;
  color: transparent;
  background: url(../images/ic-infor-nor.png) no-repeat;
  vertical-align: middle;
}
.btn-tooltip:hover {
  background: url(../images/ic-infor-hover.png) no-repeat;
}
.btn-msg {
  width: 24px;
  height: 24px;
  background: url(../images/message.png) no-repeat center center;
}
.btn-down {
  height: 26px;
  border-radius: 4px;
  border: solid 1px #3e3e3e;
  font-size: 12px;
  font-weight: 700;
  padding: 0 6px 0 30px;
  background: url(../images/ic-download.png) no-repeat 6px 3px;
}
.btn-down:hover,
.btn-down:active {
  color: #00b074;
  border-color: #00b074;
  background-image: url(../images/ic-download-selected.png);
}

.area-btn {
  padding-top: 20px;
}
.bottom-area-btn {
  margin-top: 80px;
  text-align: right;
}
.bottom-area-btn [class^="btn-"] {
  margin-left: 26px;
}

/* input */
.inp-txt {
  height: 40px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #dbdbdb;
  outline: none;
  padding: 0 20px;
  background: #fff;
  line-height: 32px;
  vertical-align: middle;
  font-family: "NanumSquare";
  font-size: inherit;
  color: #202020;
}
.inp-txt:focus {
  border-color: #00b074;
}
.inp-txt.errored {
  border-color: #dd0b00;
}
.inp-txt:disabled {
  border-color: #dbdbdb;
  background-color: rgba(219, 219, 219, 0.3);
}
.inp-txt[type="password"] {
  font-family: auto;
}

textarea,
input {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}
input[type="radio"],
input[type="checkbox"] {
  opacity: 0;
  filter: alpha(opacity=0);
  display: inline-block;
  position: absolute;
  z-index: -1;
  vertical-align: top;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  margin: 0;
}
.lb-radio,
.lb-check {
  position: relative;
  display: inline-flex;
  vertical-align: top;
  align-items: center;
  padding: 0;
  margin: 0;
  cursor: pointer;
  line-height: 1;
}
.lb-radio:before,
.lb-check:before {
  content: "";
  display: block;
  flex: 0;
  min-width: 24px;
  min-height: 24px;
  box-sizing: border-box;
}
.lb-radio:before {
  background: url(../images/deselect.png) no-repeat;
  margin-right: 6px;
}
.lb-check:before {
  background: url(../images/ic-check-nor.png) no-repeat;
  margin-right: 10px;
}
:checked + .lb-radio:before {
  background: url(../images/selected.png) no-repeat;
}
:checked + .lb-check:before {
  background: url(../images/check-box.png) no-repeat;
}
.inp-base {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  border: 1px solid #c9c9c9;
  border-radius: 2px;
  height: 40px;
  padding: 0 10px;
  outline: 0;
  margin: 0;
}
.inp-base:focus {
  border-color: #000;
}
.inp-base::-webkit-search-cancel-button {
  display: none;
}
textarea.inp-base {
  padding: 10px;
  height: 100px;
}

.inp-date {
  position: relative;
}
.inp-date:after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  background: url(../images/ic-input-calendar.png) no-repeat;
}
.inp-date.open:after {
  background: url(../images/ic-input-calendar-click.png) no-repeat;
}
.inp-date input {
  border: 0;
  width: calc(100% - 24px);
  height: 20px;
  font-family: "NanumSquare";
  font-size: 13px;
  color: #202020;
  outline: 0;
}

input[type="text"]:disabled,
.android input[type="password"]:disabled {
  background: #fafafa;
  border-color: #e1e1e1;
  color: #666;
}
textarea {
  width: 100%;
  border: 1px solid #dbdbdb;
  box-sizing: border-box;
  padding: 3px 16px;
  vertical-align: top;
  font-family: inherit;
  font-size: 14px;
  line-height: 28px;
  resize: none;
}
textarea:focus {
  border-color: #00b074;
  outline: 0;
}
.info-set-wrap textarea {
  font-family: "NanumSquare";
  height: 150px;
}

.excel {
  position: absolute;
  top: 0;
  right: 0;
}
.file-in {
  position: relative;
  display: inline-block;
  margin-left: 20px;
}
.file-in label {
  display: inline-block;
  height: 38px;
  line-height: 38px;
  padding: 0 16px;
  background: #0396a5;
  color: #fff;
  font-size: 16px;
  border-radius: 2px;
}
.file-in input[type="file"] {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.wrap {
  min-width: 1440px;
  height: 100%;
}

/* header */
.header {
  position: relative;
  height: 120px;
  background: #f2f2f2;
  border: 1px solid #979797;
  border-width: 1px 0;
}
.header:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  background: linear-gradient(to right, #ebebeb, #6dcead);
  border-bottom: 1px solid #979797;
}

/* main nav */
.nav-bar {
  position: relative;
  padding: 0 120px;
  width: 1440px;
  margin: 0 auto;
  box-sizing: border-box;
}
.nav-bar .logo {
  width: 60px;
  height: 34px;
  position: absolute;
  top: 23px;
  left: 27px;
  font-size: 0;
  background: url(../images/globlin-logo.png) no-repeat;
  background-size: cover;
}
.nav-bar .log-box {
  position: absolute;
  top: 0;
  right: 120px;
  padding-top: 30px;
  color: #fff;
}
.nav-bar .log-box > a {
  color: #fff;
  margin-left: 40px;
}
.nav-bar .log-box a > button {
  display: inline-block;
  color: #fff;
  margin-left: 25px;
}
.nav-bar ul li {
  display: inline-block;
  text-align: center;
  position: relative;
  padding: 0 40px;
}
.nav-bar ul li > a {
  display: block;
  position: relative;
  font-size: 24px;
  color: #5a6080;
  line-height: 21px;
  padding: 38px 20px 21px 20px;
  font-family: NanumSquare;
}
.nav-bar ul li.active > a,
a.active {
  color: #00b074 !important;
  font-weight: 700;
}
.nav-bar ul li.active > a:after,
.nav-bar ul li > a.active:after {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  bottom: 0;
  height: 4px;
  background: #00b074 !important;
}
.nav-bar ul li .depth {
  position: absolute;
  top: 100%;
  left: 0;
  font-size: 14px;
  line-height: 40px;
  padding-left: 40px;
  white-space: nowrap;
}
.nav-bar ul li .depth a {
  display: inline-block;
  color: #595959;
  font-family: NanumSquare;
  margin-right: 75px;
}

.footer {
  position: relative;
  border-top: solid 1px #2b2d43;
  padding: 28px;
  color: #4a4a4a;
  font-size: 12px;
  background: #fff;
  font-family: "NanumSquare";
  font-weight: 400;
  box-sizing: border-box;
}
.footer .foot-list {
  position: relative;
  width: 1200px;
  margin: 0 auto;
}
.footer .foot-list span:not(:first-child) {
  display: inline-block;
  padding-left: 20px;
  margin-left: 16px;
  position: relative;
}
.footer .foot-list span:not(:first-child):before {
  position: absolute;
  content: "";
  top: 2px;
  left: 0;
  bottom: 2px;
  width: 2px;
  background: #d5d5d5;
}
.footer .copyright {
  position: absolute;
  top: 30px;
  right: 120px;
  color: #000;
}
.footer .foot-list copy {
  position: absolute;
  top: 0;
  right: 0;
}

.login-wrap {
  margin: 0 auto;
  width: 360px;
  text-align: center;
  padding-top: 182px;
  height: calc(100% - 186px);
  min-height: 830px;
  box-sizing: border-box;
}
.login-wrap .top-txt {
  font-size: 21px;
  line-height: 24px;
}
.login-wrap h2 {
  font-size: 42px;
  line-height: 49px;
  margin-top: 12px;
  letter-spacing: -1px;
}
.login-wrap .log-input {
  margin: 52px 0 28px 0;
  text-align: left;
}
.login-wrap .log-input span {
  display: block;
  margin-bottom: 16px;
}
.login-wrap .log-input .inp-txt {
  width: 100%;
  height: 56px;
  font-size: 18px;
  padding-left: 26px;
}
.login-wrap .log-input .lb-check {
  font-size: 16px;
}
.login-wrap .log-input p {
  font-size: 12px;
  font-weight: 700;
  letter-spacing: -0.08px;
  color: #3e3e3e;
  padding: 2px 0 20px 0;
}
.login-wrap .log-input p.error-txt {
  color: #dd0b00;
}
.login-wrap .btn-cont {
  width: 100%;
  font-size: 18px;
}
.login-wrap > p {
  margin-top: 18px;
}

.step-list {
  text-align: center;
  padding-bottom: 40px;
}
.step-list li {
  display: inline-block;
  position: relative;
  width: 260px;
  font-size: 18px;
  color: #9b9b9b;
  font-family: "NanumSquare";
  font-weight: 400;
}
.step-list li a {
  color: inherit;
}
.step-list li .btn-tooltip {
  margin-right: -21px;
}
.step-list li:not(:first-child):before {
  position: absolute;
  content: "";
  top: 31px;
  left: -52px;
  width: 98px;
  height: 2px;
  background: #979797;
}
.step-list li span {
  display: block;
  width: 64px;
  height: 64px;
  font-size: 28px;
  border-radius: 100%;
  border: 2px solid #9b9b9b;
  margin: 0 auto 10px auto;
  font-weight: 800;
  padding-top: 13px;
  box-sizing: border-box;
}
.step-list li a.active {
  color: #5a6080 !important;
  font-weight: 800;
}
.step-list li a.active span {
  background: #5a6080;
  color: #fff;
  border-color: #5a6080;
}
.step-list.other li a.active {
  color: #00b074 !important;
}
.step-list.other li a.active span {
  background: #00b074;
  border-color: #00b074;
}

.content-wrap {
  padding: 40px 120px 58px 120px;
  width: 1440px;
  margin: 0 auto;
  min-height: calc(100% - 186px);
  box-sizing: border-box;
}
.content-box {
  position: relative;
  background: #fff;
  padding: 49px 48px 47px 48px;
  border-radius: 4px;
}
.content-box.disabled:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
}
.content-box.ps-center {
  padding: 59px 200px;
  margin: 0 63px;
}
.content-btn-box {
  margin-top: 53px;
  text-align: center;
}
.content-btn-box button:nth-child(2) {
  margin-left: 96px;
}

.h3 {
  font-size: 16px;
  font-family: "NanumSquare";
  font-weight: 700;
  color: #000;
  line-height: 18px;
  padding-bottom: 16px;
  margin-top: 42px;
}
.h3 span {
  font-size: 12px;
}

.form-wrap .label-txt {
  display: block;
  font-size: 16px;
  font-family: "NanumSquare";
  font-weight: 700;
  color: #000;
  line-height: 24px;
  padding-bottom: 10px;
  transform: skew(-0.03deg);
}
.form-wrap .form-box:not(:first-child) {
  margin-top: 42px;
}
.form-wrap .label-txt span {
  font-size: 12px;
}
.form-wrap .inp-group {
  border: 1px solid #dbdbdb;
  padding: 6px 0 0 17px;
  height: 40px;
  box-sizing: border-box;
}
.form-wrap .inp-group .lb-radio:not(:last-child) {
  margin-right: 36px;
}
.form-wrap.flex {
  display: flex;
  justify-content: space-between;
}
.form-wrap.flex .form-box:first-child {
  width: 440px;
}
.form-wrap.flex .form-box:nth-child(2) {
  width: 616px;
  margin: 0;
}

.line-box {
  padding: 16px 48px;
  border: 1px solid #dbdbdb;
}
.info-set-wrap {
  position: relative;
  padding-left: 192px;
}
.info-set-wrap .file-box {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 160px;
  height: 160px;
  border-radius: 100%;
}
.info-set-wrap .file-box input[type="file"] {
  position: absolute;
  height: 0;
  width: 0;
  border: 0;
}
.info-set-wrap .file-box label {
  display: block;
  width: 160px;
  height: 160px;
  cursor: pointer;
  color: transparent;
  border-radius: 50%;
  background: rgba(0, 176, 116, 0.3) url(../images/camera.png) no-repeat center
    center;
}

.flex-box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.flex-box > div {
  width: calc(50% - 16px);
}
.flex-box > div:nth-child(2) {
  margin-top: 0 !important;
}

.lang-box {
  font-family: "NanumSquare";
  position: relative;
  margin-top: 42px;
}
.lang-select-warp {
  position: relative;
}
.lang-select-warp .btn-open {
  position: absolute;
  overflow: hidden;
  top: 9px;
  right: 12px;
  width: 24px;
  height: 24px;
  background: url(../images/ic-input-dropdown.png) no-repeat;
  z-index: 5;
}
.lang-select-warp.open .btn-open {
  position: absolute;
  top: 8px;
  right: 12px;
  width: 24px;
  height: 24px;
  background: url(../images/ic-input-close.png) no-repeat;
}
.lang-select-warp.open .lang-select-box {
  border-color: #00b074;
}
.lang-select-warp .ph-txt {
  border: 1px solid #dbdbdb;
  height: 40px;
  padding-left: 20px;
  color: #b3b3b3;
  line-height: 38px;
}
.lang-select-box {
  position: relative;
  display: flex;
  border: 1px solid #dbdbdb;
  height: 38px;
  padding: 0 45px 0 22px;
}
.lang-select-box .btn-all-del {
  margin-left: auto;
  margin-top: 9px;
  width: 20px;
  height: 20px;
  background: url(../images/ic-close-lp.png) no-repeat;
}
.lang-select-box .count {
  position: absolute;
  top: 7px;
  right: 70px;
  display: block;
  width: 24px;
  height: 24px;
  border-radius: 2px;
  text-align: center;
  line-height: 22px;
  color: #fff;
  background-color: #9b9b9b;
}

.lang-sel-list {
  overflow: hidden;
  width: 322px;
  padding-top: 7px;
  display: flex;
  flex-wrap: nowrap;
}
.lang-sel-list span {
  display: inline-block;
  font-size: 12px;
  color: #3e3e3e;
  height: 24px;
  white-space: nowrap;
  box-sizing: border-box;
  background: #ebebeb;
  border-radius: 2px;
  padding: 5px 8px 4px;
  margin-right: 10px;
}

.form-wrap > .lang-sel-list {
  width: 100%;
  margin-top: 20px;
  background: rgba(242, 242, 242, 0.4);
  border-radius: 2px;
  padding: 9px 20px;
  box-sizing: border-box;
}
.form-wrap > .lang-sel-list span {
  position: relative;
  padding-right: 24px;
}
.form-wrap > .lang-sel-list .btn-del {
  position: absolute;
  top: 0;
  right: 0;
  width: 24px;
  height: 24px;
  background: url(../images/ic-close-narrow.png) no-repeat center center;
}
.form-wrap > .lang-sel-list p {
  line-height: 24px;
  font-size: 12px;
  color: #9b9b9b;
}

.lang-select-conts {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  top: calc(100% + 4px);
  border: 1px solid #dbdbdb;
  background: #fff;
  z-index: 10;
  max-height: 20rem;
  overflow-y: auto;
}
.lang-select-conts li {
  padding: 8px 19px;
  position: relative;
}
.lang-select-conts li:hover:before {
  position: absolute;
  content: "";
  left: -1px;
  right: -1px;
  top: 0;
  bottom: 0;
  border: 1px solid #00b074;
}
.lang-select-warp.open .lang-select-conts {
  display: block;
}

.contents {
  padding: 42px 0 120px 0;
}
.campaign-wrap {
  font-family: "NanumSquare";
  transform: skew(-0.03deg);
}
.campaign-item {
  padding: 32px 48px;
  margin-top: 40px;
  border-radius: 4px;
  border: solid 1px #808080;
  background: #ffffff;
}
.campaign-item:first-child {
  margin-top: 0;
}
.campaign-item .title {
  border-bottom: 1px solid #cbcbcb;
  padding-bottom: 10px;
}
.campaign-item .title .name {
  display: block;
  font-size: 20px;
  font-weight: 800;
}
.campaign-item .title .date {
  display: block;
  font-size: 12px;
  margin-top: 6px;
}
.campaign-item .campaign-info {
  position: relative;
  padding: 23px 212px 0 174px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
.campaign-item .campaign-info .photo {
  position: absolute;
  top: 23px;
  left: 0;
  width: 140px;
  height: 140px;
  border-radius: 100%;
  border: solid 1px #808080;
  overflow: hidden;
}
.campaign-item .campaign-info .info-list {
  width: 304px;
  border: solid 1px #dcdcdc;
  background: #f8f8f8;
  font-size: 15px;
  color: #3e3e3e;
  font-weight: 700;
  padding: 16px 24px 16px 20px;
  box-sizing: border-box;
}
.campaign-item .campaign-info .info-list span {
  display: block;
  font-size: 12px;
  font-weight: 400;
  padding-bottom: 2px;
}
.campaign-item .campaign-info .info-list ul {
  display: flex;
  flex-wrap: wrap;
}
.campaign-item .campaign-info .info-list li {
  margin-top: 14px;
  width: 100%;
}
.campaign-item .campaign-info .info-list li:nth-child(1) {
  width: 91px;
  margin: 0;
}
.campaign-item .campaign-info .info-list li:nth-child(2) {
  width: calc(100% - 91px);
  margin: 0;
}
.campaign-item .campaign-info .btn-box {
  position: absolute;
  right: 0;
  bottom: 0;
}
.campaign-item .campaign-info .btn-box button {
  height: 50px;
  width: 160px;
  font-size: 16px;
  line-height: 50px;
}
.campaign-item .campaign-info .status-box {
  width: 362px;
}
.status-box .status-bar {
  position: relative;
  padding-bottom: 54px;
}
.status-box .status-bar strong {
  display: block;
  width: 100%;
  height: 16px;
  border-radius: 8px;
  background: #d5d5d5;
  padding: 3px;
  box-sizing: border-box;
}
.status-box .status-bar strong span {
  display: block;
  height: 10px;
  background: #00b074;
  border-radius: 5px;
}
.status-box .status-bar > span {
  position: absolute;
  top: 26px;
  font-size: 15px;
  font-weight: 700;
  color: #d5d5d5;
}
.status-box .status-bar .txt-step1 {
  left: 0;
}
.status-box .status-bar .txt-step2 {
  left: 50%;
  width: 60px;
  margin-left: -30px;
  text-align: center;
}
.status-box .status-bar .txt-step3 {
  right: 0;
}
.status-box .status-bar.matching strong span {
  width: 10px;
}
.status-box .status-bar.matching .txt-step1 {
  font-size: 18px;
  font-weight: 800;
  color: #00b074;
}
.status-box .status-bar.ongoing strong span {
  width: 50%;
}
.status-box .status-bar.ongoing .txt-step1 {
  color: rgba(0, 176, 116, 0.6);
}
.status-box .status-bar.ongoing .txt-step2 {
  font-size: 18px;
  font-weight: 800;
  color: #00b074;
}
.status-box .status-bar.complete strong span {
  width: 100%;
}
.status-box .status-bar.complete .txt-step1,
.status-bar.complete .txt-step2 {
  color: rgba(0, 176, 116, 0.6);
}
.status-box .status-bar.complete .txt-step3 {
  font-size: 18px;
  font-weight: 800;
  color: #00b074;
}
.status-box .textbox {
  position: relative;
  height: calc(100% - 72px);
  border: solid 1px #d5d5d5;
  padding: 18px 29px;
  line-height: 22px;
  box-sizing: border-box;
}
.status-box .textbox:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background: #ec541e;
}
.btn-new-campaign {
  display: block;
  width: 100%;
  font-size: 18px;
  color: #9b9b9b;
  text-align: center;
  margin-top: 40px;
  border: solid 1px #808080;
  padding: 31px 0;
  background: #fff;
}
.btn-new-campaign span {
  display: inline-block;
  padding-left: 64px;
  position: relative;
  line-height: 50px;
  font-weight: 700;
}
.btn-new-campaign span:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  background: url(../images/ic-add.png) no-repeat;
}

.campaign-wrap .filter-list {
  text-align: right;
}
.campaign-wrap .filter-list button {
  font-family: "NanumSquare";
  font-weight: 700;
  margin-left: 34px;
  color: #3e3e3e;
  transform: skew(-0.03deg);
  outline: 0;
}
.campaign-wrap .filter-list button.on {
  font-weight: 800;
  color: #00b074;
}
.influencer-list {
  margin-top: 14px;
}
.influencer-item {
  position: relative;
  border-radius: 4px;
  border: solid 1px #808080;
  background-color: #ffffff;
  padding: 64px 64px 64px 360px;
}
.campaign-wrap .influencer-item:not(:first-child) {
  margin-top: 40px;
}
.influencer-item .profile {
  position: absolute;
  top: 64px;
  left: 148px;
}
.influencer-item .profile .photo {
  display: block;
  width: 155px;
  height: 155px;
  border-radius: 100%;
  border: solid 1px #808080;
  overflow: hidden;
}
.influencer-item .profile .photo img {
  width: 100%;
}
.influencer-item .check {
  position: absolute;
  top: 64px;
  left: 64px;
}
.influencer-item .check .lb-check {
  font-size: 0;
}
.influencer-item .check .lb-check:before {
  min-width: 36px;
  min-height: 36px;
  margin: 0;
  background: url(../images/check-box-outline-blank.png) no-repeat;
}
.influencer-item .check :checked + .lb-check:before {
  background: url(../images/check-box-large.png) no-repeat;
}
.influencer-item .btn-line {
  position: absolute;
  top: 64px;
  right: 64px;
}
.influencer-item .use-platform {
  text-align: center;
  display: flex;
  justify-content: center;
  margin: 14px -6px 0 -6px;
}
.use-platform span {
  display: inline-block;
  width: 30px;
  height: 30px;
  font-size: 1px;
  color: transparent;
  overflow: hidden;
  margin: 0 6px;
}
.use-platform span.youtube {
  background: url(../images/youtube.png) no-repeat;
  background-size: 100% 100%;
}
.use-platform span.facebook {
  background: url(../images/facebook.png) no-repeat;
  background-size: 100% 100%;
}
.use-platform span.instagram {
  background: url(../images/instagram.png) no-repeat;
  background-size: 100% 100%;
}
.use-platform span.twitter {
  background: url(../images/twitter.png) no-repeat;
  background-size: 100% 100%;
}

.info-wrap {
  position: relative;
  font-family: "NanumSquare";
  color: #000;
}
.influencer-item .info-wrap .title {
  min-height: 64px;
  border-bottom: 1px solid #979797;
}
.info-wrap .title .cate {
  display: inline-block;
  height: 20px;
  border-radius: 15px;
  border: solid 2px #00b074;
  text-align: center;
  padding-top: 1px;
  font-size: 12px;
  color: #00b074;
  vertical-align: top;
  margin: 5px 10px 0 0;
  font-weight: 700;
  box-sizing: border-box;
  transform: skew(-0.03deg);
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.info-wrap .title strong {
  font-family: auto;
  font-size: 25px;
}
.tagbox {
  font-size: 16px;
  font-weight: 700;
  color: #9b9b9b;
  margin-top: 14px;
  transform: skew(-0.03deg);
}
.tagbox span {
  margin-right: 12px;
}
.inf-index {
  position: relative;
  padding-top: 10px;
}
.inf-index ul {
  display: flex;
  flex-wrap: wrap;
  width: 480px;
}
.inf-index ul li {
  width: 25%;
  font-size: 15px;
  margin-top: 18px;
}
.inf-index ul li:last-child {
  width: 75%;
}
.inf-index ul li span {
  line-height: 24px;
}
.inf-index ul li strong {
  display: block;
  font-size: 19px;
  font-weight: 800;
  margin-top: 5px;
}
.inf-index ul li strong[class^="star"] {
  width: 100px;
  height: 20px;
  background: url(../images/star-filled.png) no-repeat;
}
.inf-index ul li strong.star5 {
  background-position: 0 0;
}
.inf-index ul li strong.star4 {
  background-position: 0 -20px;
}
.inf-index ul li strong.star3 {
  background-position: 0 0;
}
.inf-index ul li strong.star2 {
  background-position: 0 0;
}
.inf-index ul li strong.star1 {
  background-position: 0 0;
}
.thumbnail {
  display: flex;
  width: 254px;
  justify-content: space-between;
  flex-wrap: wrap;
  position: absolute;
  right: 0;
  bottom: 0;
}
.thumbnail span {
  width: 122px;
  height: 64px;
  overflow: hidden;
  margin-top: 10px;
}

.campaign-mng {
  font-family: "NanumSquare";
  transform: skew(-0.03deg);
  position: relative;
  padding-right: 471px;
}
.campaign-mng .influencer-item-list {
  background: #fff;
  padding: 24px 12px 24px 24px;
  min-height: 734px;
  box-sizing: border-box;
}
.campaign-mng .influencer-item-list .influencer-empty {
  margin: 320px auto;
  width: 300px;
  font-size: 20px;
  font-family: NanumSquareEB;
  letter-spacing: -0.14px;
  text-align: center;
}
.campaign-mng .influencer-scroll {
  max-height: 1535px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 24px;
}
.campaign-mng .influencer-item {
  padding: 42px 42px 42px 178px;
}
.campaign-mng .influencer-item:not(:first-child) {
  margin-top: 40px;
}
.campaign-mng .influencer-item .profile {
  top: 42px;
  left: 42px;
}
.campaign-mng .influencer-item .profile .photo {
  width: 100px;
  height: 100px;
}
.campaign-mng .influencer-item .inf-index ul {
  width: 100%;
}
.campaign-mng .info-wrap .title {
  min-height: 46px;
}
.campaign-mng .info-wrap .title strong {
  font-size: 24px;
  display: inline-block;
  width: calc(100% - 64px);
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  overflow: hidden;
}
.campaign-mng .influencer-item .check {
  position: static;
  margin-top: 35px;
}
.campaign-mng .influencer-item .check .btns-box {
  margin-top: 40px;
  text-align: right;
}
.campaign-mng .influencer-item .btn-close {
  top: 16px;
  right: 16px;
}
.campaign-mng .selected-wrap {
  position: absolute;
  top: 0;
  right: 0;
  width: 460px;
  border-radius: 4px;
  border: solid 1px #808080;
  background-color: #ffffff;
  padding: 46px 42px 40px 42px;
  box-sizing: border-box;
}
.campaign-mng .selected-wrap h2 {
  font-size: 22px;
  color: #3e3e3e;
  font-weight: 800;
  padding-bottom: 25px;
}
.table-box-wrap {
  position: relative;
  padding-top: 40px;
  border-top: 1px solid #757575;
}
.table-box-wrap .tablel-box {
  height: 320px;
  overflow-y: auto;
}
.table-box-wrap table thead tr {
  position: absolute;
  top: 0;
  display: inline-table;
  width: 100%;
  table-layout: fixed;
}
.table-box-wrap table thead tr th:first-child {
  width: 256px;
}

.campaign-mng .selected-wrap table {
  font-family: "NanumSquare";
}
.campaign-mng .selected-wrap table thead th {
  font-size: 12px;
  height: 40px;
  border-bottom: 1px solid #757575;
  text-align: left;
  font-weight: 800;
}
.campaign-mng .selected-wrap table tbody th {
  border-bottom: 1px solid #dbdbdb;
  padding: 19px 0 19px 56px;
  color: #3e3e3e;
}
.campaign-mng .selected-wrap table tbody td {
  vertical-align: middle;
  position: relative;
  border-bottom: 1px solid #dbdbdb;
}

.campaign-mng .selected-wrap th:first-child {
  padding-left: 28px;
}
.campaign-mng .selected-wrap .conts-type {
  position: relative;
  padding-left: 56px;
  color: #3e3e3e;
  text-align: left;
}
.campaign-mng .selected-wrap .conts-type .photo {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
}
.campaign-mng .selected-wrap .conts-type .photo img {
  width: 100%;
}
.campaign-mng .selected-wrap .conts-type strong {
  font-family: auto;
  display: block;
  font-size: 15px;
  font-weight: 800;
  margin: 0 4px 2px 4px;
  color: #000;
}
.campaign-mng .selected-wrap table .cnt {
  display: flex;
  padding-right: 25px;
  position: relative;
  min-height: 24px;
  line-height: 24px;
}
.campaign-mng .selected-wrap table .cnt strong {
  font-weight: 800;
  color: #000;
}
.campaign-mng .selected-wrap table .cnt .btn-minus {
  width: 20px;
  height: 20px;
  line-height: 24px;
  font-size: 20px;
  font-weight: 700;
  color: #757575;
}
.campaign-mng .selected-wrap table .cnt .btn-plus {
  width: 20px;
  height: 20px;
  line-height: 24px;
  font-size: 18px;
  font-weight: 700;
  color: #757575;
}
.campaign-mng .selected-wrap table .cnt .btn-del {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 2px;
  right: 32px;
  background: url(../images/ic-close-narrow.png) no-repeat center center;
}
.selected-wrap .total-cnt {
  border-top: 1px solid #757575;
  padding: 18px 28px 0 28px;
}
.selected-wrap .total-cnt .title {
  font-size: 18px;
  color: #00b074;
  font-weight: 800;
}
.selected-wrap .total-cnt .total-info {
  margin-top: 20px;
  font-size: 12px;
  font-weight: 700;
}
.selected-wrap .total-cnt .total-info span {
  display: inline-block;
  width: 100px;
}
.selected-wrap .total-cnt .total-info strong {
  display: block;
  font-size: 24px;
  font-weight: 800;
  padding-top: 4px;
}

.align-left {
  text-align: left !important;
}
.table-wrap {
  padding: 24px 12px;
  background: #fff;
}
.tbl-data {
  border-top: 1px solid #757575;
  font-family: "NanumSquare";
}
.tbl-data thead th {
  font-weight: 800;
  height: 50px;
  background: #f8f8f8;
  border-bottom: 1px solid #757575;
}
.tbl-data tbody td {
  padding: 18px 6px;
  border-bottom: 1px solid #d5d5d5;
  font-size: 13px;
  text-align: center;
}
.tbl-data tbody td:first-child,
.tbl-data thead th:first-child {
  padding-left: 12px;
}
.tbl-data tbody td:last-child,
.tbl-data thead th:last-child {
  padding-right: 12px;
}
.tbl-data tbody tr:last-child td {
  border-color: #757575 !important;
}
.name-tag {
  font-family: auto;
  position: relative;
  padding-left: 46px;
  font-weight: 700;
  font-size: 13px;
  text-align: left;
  line-height: 36px;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  overflow: hidden;
}
.name-tag span {
  position: absolute;
  top: 0;
  left: 0;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  overflow: hidden;
}
.name-tag span img {
  width: 100%;
}
.txt-make {
  color: #00b074;
}
.txt-cancel {
  color: #ec541e;
}

.review-wrap .review-item {
  background: #fff;
  padding: 36px 48px;
  font-family: "NanumSquare";
  transform: skew(-0.03deg);
}
.review-wrap .review-item:not(:first-child) {
  margin-top: 40px;
}
.review-wrap .review-item .name-tag {
  padding-left: 76px;
  line-height: 60px;
  font-size: 18px;
  font-weight: 800;
}
.review-wrap .review-item .name-tag span {
  width: 60px;
  height: 60px;
}
.review-info-wrap {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}
.review-info-wrap .info-box {
  width: 258px;
  border-right: 1px solid #757575;
}
.review-info-wrap .info-box li {
  font-size: 14px;
  font-weight: 800;
}
.review-info-wrap .info-box li:not(:first-child) {
  margin-top: 12px;
}
.review-info-wrap .info-box li span {
  display: inline-block;
  width: 80px;
  font-size: 13px;
  font-weight: 400;
}
.review-info-wrap .btns-wrap {
  border-left: 1px solid #757575;
  padding-left: 39px;
  display: flex;
  align-items: center;
}
.review-info-wrap .btns-wrap button {
  display: block;
}
.review-info-wrap .btns-wrap a button {
  margin-top: 47px;
}
.review-info-wrap .status-box {
  width: 604px;
}
.review-info-wrap .status-box .status-bar {
  padding-bottom: 20px;
}
.review-info-wrap .status-box .status-bar strong {
  height: 12px;
}
.review-info-wrap .status-box .status-bar strong span {
  height: 6px;
}
.review-info-wrap .status-box .status-bar ul {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.review-info-wrap .status-box .status-bar ul li {
  font-weight: 700;
  color: #d5d5d5;
}
.review-info-wrap .status-box .status-bar.ing1 strong span {
  width: 6px;
}
.review-info-wrap .status-box .status-bar.ing1 .txt-ing1,
.review-info-wrap .status-box .status-bar.ing2 .txt-ing2,
.review-info-wrap .status-box .status-bar.ing3 .txt-ing3,
.review-info-wrap .status-box .status-bar.ing4 .txt-ing4,
.review-info-wrap .status-box .status-bar.ing5 .txt-ing5 {
  font-size: 15px;
  font-weight: 800;
  color: #00b074;
}
.review-info-wrap .status-box .status-bar.ing1 strong span {
  width: 6px;
}
.review-info-wrap .status-box .status-bar.ing2 strong span {
  width: 25%;
}
.review-info-wrap .status-box .status-bar.ing3 strong span {
  width: 50%;
}
.review-info-wrap .status-box .status-bar.ing4 strong span {
  width: 75%;
}
.review-info-wrap .status-box .status-bar.ing5 strong span {
  width: 100%;
}
.review-info-wrap .status-box .make-info {
  position: relative;
  padding-left: 172px;
  min-height: 90px;
}
.review-info-wrap .status-box .make-info .thum-img {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 156px;
  height: 88px;
  border: solid 1px #979797;
  background-color: #d8d8d8;
}
.review-info-wrap .status-box .make-info .thum-img img {
  width: 156px;
  height: 88px;
  object-fit: cover;
}
.review-info-wrap .status-box .make-info p {
  margin-bottom: 14px;
}
.review-info-wrap .status-box .make-info p strong {
  font-weight: 800;
  margin-right: 10px;
}
.review-info-wrap .status-box .make-info .btn-cont {
  margin-right: 8px;
}
.review-info-wrap .status-box .form-wrap {
  position: relative;
  padding: 0 80px 0 84px;
  margin-top: 20px;
}
.review-info-wrap .status-box .form-wrap .label-txt {
  position: absolute;
  left: 0;
  top: 11px;
  padding: 0;
  width: auto;
  font-size: 14px;
}
.review-info-wrap .status-box .form-wrap .btn-cont {
  position: absolute;
  top: 6px;
  right: 0;
}

.pop-wrap {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000;
}
.pop-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 968px;
  border-radius: 4px;
  background: #fff;
  padding: 55px 48px;
  box-sizing: border-box;
}
.pop-wrap .pop-h1 {
  font-size: 25px;
  color: #3e3e3e;
  padding-bottom: 16px;
  border-bottom: 2px solid #757575;
  font-family: "NanumSquare";
  font-weight: 700;
}
.pop-wrap .pop-h2 {
  display: block;
  font-size: 22px;
  color: #3e3e3e;
  padding-bottom: 15px;
  margin: 80px 0 48px 0;
  border-bottom: 2px solid #757575;
  font-family: "NanumSquare";
  font-weight: 800;
}
.pop-wrap .pop-btn {
  padding-top: 26px;
  text-align: center;
  font-size: 14px;
}
.pop-wrap .pop-btn .btn-grey {
  width: 120px;
}
.pop-wrap .pop-btn-wrap {
  margin-top: 66px;
  text-align: center;
}
.pop-wrap .pop-btn-wrap button:nth-child(2) {
  margin-left: 16px;
}
.btn-close {
  position: absolute;
  width: 32px;
  height: 32px;
  font-size: 0;
  background: url(../images/ic-close-lp.png) no-repeat center center;
}
.pop-wrap .btn-close {
  top: 18px;
  right: 18px;
}
.pop-wrap.alert {
  width: auto;
  padding: 60px 46px 48px 46px;
}
.pop-wrap.alert .pop-h1 {
  font-size: 20px;
  text-align: center;
  height: 70px;
  margin: -60px -46px 40px -46px;
  line-height: 70px;
  padding: 0;
}
.pop-wrap.alert .msg-box {
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
}
.pop-wrap.alert .msg-box p:not(:first-child) {
  margin-top: 22px;
}
.pop-wrap.alert .pop-btn-wrap {
  margin-top: 40px;
}
/* .pop-wrap.large {padding:0 42px;} */
.pop-wrap.large .pop-content {
  width: 1200px;
  padding: 55px 100px;
  max-height: 84%;
  overflow-y: auto;
}
.pop-wrap.large .graph-box .y-axis {
  color: #808080;
  font-family: "NanumSquare";
  font-weight: 700;
  margin-bottom: 14px;
  margin-left: 4px;
}
.pop-wrap.large .graph-box {
  height: 360px;
}

/* .inner-conts {padding:0 58px;} */
.thumnail-list-wrap {
  display: flex;
  justify-content: space-between;
}
.thumnail-list-wrap li {
  width: 238px;
}
.thumnail-list-wrap li .img {
  display: block;
  width: 238px;
  height: 134px;
  overflow: hidden;
}
.thumnail-list-wrap li p {
  height: 26px;
  display: flex;
  justify-content: space-between;
  background: #000;
  color: #fff;
  padding: 0 16px;
  font-family: "NanumSquare";
  font-weight: 800;
  transform: skew(-0.03deg);
  line-height: 26px;
}

.post-wrap p {
  font-size: 16px;
  font-family: "NanumSquare";
  font-weight: 700;
}
.post-wrap p span {
  font-size: 17px;
  color: #00b074;
}
.line-conts-box {
  padding: 33px;
  margin-top: 32px;
  border-radius: 5px;
  border: solid 1px #808080;
}

.info-list {
  font-size: 16px;
  font-family: "NanumSquare";
  font-weight: 400;
  padding-top: 18px;
  color: #202020;
  transform: skew(-0.03deg);
}
.info-list div {
  display: flex;
  padding: 13px 0;
}
.info-list div:nth-child(even) {
  background: #f8f8f8;
}
.info-list div dt {
  width: 245px;
  font-weight: 700;
  padding-left: 52px;
}

.influencer-info {
  position: relative;
}
.influencer-info .info-wrap {
  margin-left: 190px;
}
.influencer-info > .photo {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 155px;
  height: 155px;
  border-radius: 100%;
  border: solid 1px #808080;
  overflow: hidden;
}
.influencer-info .use-platform {
  margin-top: 15px;
  text-align: center;
}
.influencer-info .use-platform span {
  margin: 0;
  border-radius: 50%;
}
.influencer-info .profile {
  position: absolute;
  top: 0;
  left: 0;
}

.daterangepicker {
  border-color: #979797;
  border-radius: 0;
  font-family: "NanumSquare";
  transform: skew(-0.03deg);
}
.daterangepicker:before,
.daterangepicker:after {
  display: none;
}
.daterangepicker th.month {
  font-size: 18px !important;
  font-weight: 800;
  color: #000 !important;
}
.daterangepicker .calendar-table th {
  font-size: 12px;
  color: #757575;
}
.daterangepicker .calendar-table td {
  font-size: 14px;
  font-weight: 800;
}

.analisys-wrap {
  background: #fff;
  padding: 48px;
  position: relative;
}
.analisys-wrap h2 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 42px;
}
.analisys-wrap .btn-back {
  display: inline-block;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 14px;
  padding-left: 46px;
  line-height: 28px;
  background: url(../images/ic-arrow-back.png) no-repeat;
}
.analisys-wrap > .select {
  width: 704px;
}

.analisys-wrap .scroll-container-wrap {
  margin-top: 28px;
  background: #f2f2f2;
  padding: 32px 32px 11px 32px;
}
.overscroll {
  white-space: nowrap;
  overflow-x: auto;
  vertical-altin: top;
}
.overscroll .influencer-item {
  width: 672px;
  display: inline-block;
  box-sizing: border-box;
  cursor: pointer;
}
.overscroll .influencer-item:not(:first-child) {
  margin-left: 28px;
}

.analisys-wrap .influencer-item {
  padding: 42px 42px 42px 170px;
  font-family: "NanumSquare";
}
.analisys-wrap .influencer-item.selected {
  border: solid 2px #00b074;
  background-color: #f2fbf8;
}
.analisys-wrap .influencer-item .profile {
  top: 42px;
  left: 42px;
}
.analisys-wrap .influencer-item .profile .photo {
  width: 92px;
  height: 92px;
}
.analisys-wrap .use-platform span {
  width: 20px;
  height: 20px;
  margin: 0 2px;
}
.analisys-wrap .info-wrap .title strong {
  font-size: 24px;
  display: inline-block;
  width: calc(100% - 66px);
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  overflow: hidden;
}
.analisys-wrap .influencer-item .info-wrap .title {
  min-height: 45px;
}
.analisys-wrap .inf-index ul {
  width: 100%;
}
.analisys-wrap .inf-index ul li {
  width: 100px;
}
.analisys-wrap .inf-index ul li:not(:first-child) {
  margin-left: 17px;
}
.analisys-wrap .info-wrap .btns-box {
  margin-top: 35px;
  text-align: right;
}
.analisys-wrap .info-wrap .btn-line {
  position: static;
  background: none;
}

.analisys-wrap .conts-add {
  margin-top: 28px;
  padding: 5px 0;
  position: relative;
}
.analisys-wrap .conts-add .select {
  width: 704px;
}
.analisys-wrap .conts-add .btn-cont {
  position: absolute;
  top: 0;
  left: 718px;
}

.report-wrap {
  border-radius: 4px;
  background: #fff;
  padding: 24px 48px 48px 48px;
  font-family: "NanumSquare";
  transform: skew(-0.03deg);
}
.report-wrap .top-btn {
  position: absolute;
  right: 48px;
  top: 27px;
  text-align: right;
  margin-bottom: 36px;
}
.report-wrap .filter-wrap {
  display: flex;
  justify-content: space-between;
}
.report-wrap .filter-wrap .form-box {
  width: 336px;
}
.report-wrap .filter-wrap .label-txt {
  display: block;
  font-size: 16px;
  font-family: "NanumSquare";
  font-weight: 700;
  color: #000;
  line-height: 24px;
  padding-bottom: 10px;
  transform: skew(-0.03deg);
}
.report-wrap h2 {
  font-size: 22px;
  font-weight: 800;
  padding-bottom: 15px;
  border-bottom: solid 2px #979797;
  margin: 58px 0 18px 0;
}
.report-wrap .chart-box {
  height: 300px;
  border: solid 1px #979797;
  padding: 30px;
}
.txt-para {
  font-size: 15px;
  line-height: 25px;
  padding-bottom: 18px;
}

.scrollable {
  width: 100%;
  overflow: auto;
  border-top: 1px solid #000;
}
.tbl-report {
  /* border-top: 1px solid #000; */
  border-left: 1px solid #d5d5d5;
  font-size: 12px;
}
.tbl-report thead th {
  font-weight: 800;
  height: 45px;
  color: #202020;
  text-align: center;
  border-bottom: 1px solid #757575;
  border-right: 1px solid #d5d5d5;
  background: #d0f0e5;
}
.tbl-report thead th:first-child {
  border-right-color: #757575;
}
.tbl-report tbody th {
  text-align: center;
  background: #ecf9f5;
  font-weight: 800;
  border-right: 1px solid #757575;
  border-bottom: 1px solid #d5d5d5;
}
.tbl-report tbody th .tooltip-box {
  margin-top: -5px;
}
.tbl-report tbody td {
  height: 45px;
  padding: 0 12px;
  text-align: right;
  border: solid 1px #d5d5d5;
  border-width: 0 1px 1px 0;
}
.tbl-report tbody tr:last-child td,
.tbl-report tbody tr:last-child th {
  border-bottom-color: #757575;
}
/* .tbl-report tbody:last-child tr:last-child td:nth-child(2) {
  background: #abe5d1;
  color: #00b074;
} */
.tbl-report tbody td:first-child {
  text-align: left;
  font-weight: 700;
}
.tbl-report tbody td[colspan] {
  text-align: left;
  background: #dbdbdb;
  font-weight: 800;
  border-bottom: 1px solid #757575;
}
.tbl-report tbody:nth-of-type(1) td {
  background: #ecf9f5;
}
.tbl-report .fixed {
  position: sticky;
  left: 0;
}
.tbl-report .fixed.top {
  /* border-top: 1px solid #000; */
  position: sticky;
  top: 1px;
  z-index: 100;
  background-color: #abe5d1;
}
.tbl-report .fixed.top-46 {
  position: sticky;
  top: 47px;
  height: 75px;
  z-index: 100;
}
.tbl-report .fixed.top-92 {
  position: sticky;
  top: 123px;
  z-index: 100;
}
.tbl-report .fixed.top-138 {
  position: sticky;
  top: 169px;
  z-index: 100;
}
.tbl-report .fixed.top-184 {
  position: sticky;
  top: 215px;
  z-index: 100;
}
.tbl-report .fixed.top-230 {
  position: sticky;
  top: 261px;
  z-index: 100;
}
.tbl-report .fixed.top-276 {
  position: sticky;
  top: 307px;
  z-index: 100;
  background-color: #dcdbdb;
}

.tooltip-box {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
  font-size: 1px;
  color: transparent;
  background: url(../images/ic-infor-nor.png) no-repeat;
  vertical-align: middle;
}
.step-list li .tooltip-box {
  margin-right: -21px;
}
.tooltip-box:hover {
  background: url(../images/ic-infor-hover.png) no-repeat;
}
.tooltip-box:hover .tooltip-base {
  display: block;
}
.tooltip-base {
  display: none;
  position: absolute;
  top: 0;
  left: 100%;
  font-size: 14px;
  white-space: nowrap;
  color: #202020;
  text-align: left;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  background: #fff;
  padding: 20px 24px;
  font-family: "NanumSquare";
  transform: skew(-0.03deg);
  font-weight: 700;
  line-height: 22px;
  z-index: 10;
}
.tooltip-base.right {
  left: auto;
  top: 28px;
  right: -40px;
}
.tbl-data .tooltip-base {
  left: auto;
  top: 100%;
  right: 0;
}
/* .tooltip-base {display:none; font-size:14px; white-space:nowrap; color:#202020; text-align:left; border:1px solid #dbdbdb; border-radius:4px; background:#fff; padding:24px;font-family: 'NanumSquare'; transform: skew(-0.03deg); font-weight:700; line-height:22px; z-index:10;}
.tooltip-base.bottom {left:auto; top:100%; right:0; } */

/* intro */
.main-intro-wrap {
  font-family: "Nanum Gothic";
}
.main-intro-wrap img {
  max-width: 100%;
}
.intro-top {
  /* background: #00b074; */
  background: none;
}
.intro-top .inner {
  max-width: 1054px;
  margin: 0 auto;
  color: #fff;
  text-align: center;
  padding: 140px 0;
  font-size: 30px;
  font-family: "Nanum Gothic";
  font-weight: 800;
  line-height: 46px;
}
.intro-top .inner .top-txt {
  margin: 0 42px;
  padding-bottom: 32px;
  border-bottom: 2px solid #fff;
  text-align: left;
}
.intro-top .inner .main-stand {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 30px;
  padding-top: 50px;
  padding-bottom: 40px;
  font-size: 40px;
}
.intro-top .inner .main-stand .logo {
  display: block;
  margin: 36px 0 20px 0;
  height: 121px;
  background: url(../images/globlin-logo-outlined-yellow.png) center 0 no-repeat;
}
.intro-top .inner .main-stand .intro-description {
  font-size: 1.5rem;
}
.intro-top .inner .btn-start {
  display: inline-block;
  width: 228px;
  height: 72px;
  border-radius: 4px;
  border: solid 2px #ffffff;
  text-align: center;
  line-height: 70px;
  font-size: 19px;
  font-weight: 700;
  color: #fff;
}

.embed_youtube {
  margin: auto;
  width: 1080px;
  height: 607px;
}

.main-info {
  text-align: center;
  padding: 50px 0;
  background: #fff;
}
.main-info p {
  font-size: 33px;
  line-height: 50px;
  font-weight: 700;
  margin-bottom: 65px;
}
.main-info strong {
  display: block;
  font-size: 48px;
  font-weight: 700;
  color: #00b074;
}
.main-info strong br,
.main-conts .mbr {
  display: none;
}
.main-conts {
  padding: 80px 0 180px 0;
  text-align: center;
}
.main-conts strong {
  display: block;
  font-size: 33px;
  line-height: 60px;
}
.main-conts p {
  font-size: 18px;
  line-height: 30px;
  margin: 26px 0 70px 0;
}
.main-conts.bg-white {
  background: #fff;
}
.img-box {
  max-width: 1140px;
  margin: 0 auto;
}
.img-box .first {
  text-align: left;
}
.img-box .first img:first-child {
  display: block;
  margin-bottom: 29px;
}
.img-box .second {
  text-align: right;
  margin-top: -235px;
  margin-right: -21px;
}
.img-box .third {
  text-align: right;
}
.img-box .fourth {
  text-align: left;
  margin-top: -203px;
}
.chart-wrap {
  width: 80px;
  height: 80px;
  position: absolute;
  left: 48px;
  bottom: 48px;
  border-radius: 50%;
  background: conic-gradient(#a7e3d7, #00b074);
}
.chart-wrap .pie-chart {
  position: absolute;
  left: -1px;
  right: -1px;
  top: -1px;
  bottom: -1px;
  display: block;
  width: 82px;
  height: 82px;
  border-radius: 50%;
  transition: 0.3s;
}
.chart-wrap .pie-chart span.center {
  background: #fff;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  text-align: center;
  padding-top: 17px;
  line-height: 20px;
  color: #000;
  font-size: 12px;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
}
.chart-wrap .pie-chart span.center strong {
  display: block;
  font-size: 14px;
  font-weight: 800;
}
.selected .pie-chart span.center {
  background: #f2fbf8;
}

@media screen and (max-width: 768px) {
  .intro-top .inner {
    width: 100%;
    padding: 20px 20px 40px 20px;
    box-sizing: border-box;
  }
  .intro-top .inner .top-txt {
    font-size: 12px;
    line-height: 20px;
    padding-bottom: 16px;
    margin: 0;
  }
  .intro-top .inner .main-stand {
    padding-top: 27px;
    font-size: 1.2rem;
    line-height: 20px;
  }
  .intro-top .inner .main-stand .logo {
    height: 34px;
    background-size: auto 34px;
    margin: 36px 0 32px 0;
  }
  .intro-top .inner .main-stand .intro-description {
    font-size: 1rem;
  }
  .intro-top .inner .btn-start {
    width: 160px;
    height: 40px;
    font-size: 14px;
    line-height: 39px;
  }
  .btn-login {
    width: 160px;
    height: 40px;
    font-size: 14px;
    line-height: 39px;
  }
  .embed_youtube {
    margin: auto;
    width: 360px;
    height: 201px;
  }
  .main-info {
    padding: 40px 0;
  }
  .main-info p {
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 32px;
  }
  .main-info strong {
    font-size: 16px;
    line-height: 28px;
  }
  .main-info strong br,
  .main-conts .mbr {
    display: block;
  }
  .main-conts strong {
    font-size: 16px;
    line-height: 28px;
    width: 188px;
    margin: 0 auto;
  }
  .main-conts {
    padding: 40px 0;
  }
  .main-conts p {
    font-size: 12px;
    line-height: 18px;
    margin: 32px 0 40px 0;
  }
  .img-box {
    width: 100%;
  }
  .img-box .first {
    padding: 0 36% 0 20px;
  }
  .img-box .first img:first-child {
    margin-bottom: 4px;
  }
  .img-box .second {
    margin-right: -10px;
    margin-top: -15px;
    padding: 0 20px 0 13.8%;
  }
  .main-conts.bg-white {
    padding: 40px 10px 40px 15px;
  }
  .img-box .third {
    padding: 0 20px 0 42.5%;
  }
  .img-box .fourth {
    padding: 0 32% 0 20px;
    margin-top: -100px;
  }
}

/* 210222 filter area */
.filter-wrap {
  background: #fff;
  padding: 38px 0;
}
.filter-wrap .inner {
  width: 1200px;
  margin: 0 auto;
}
.filter-wrap > .filter-detail {
  width: 1200px;
  margin: -30px auto 0 auto;
  padding: 38px 0;
}
.filter-wrap .filter-top {
  position: relative;
  padding-right: 168px;
}
.filter-wrap .filter-top .btn-filter {
  position: absolute;
  top: 0;
  right: 0;
  width: 132px;
  height: 40px;
  border: 1px solid #00b074;
  border-radius: 4px;
  text-align: left;
  padding-left: 36px;
  font-size: 14px;
  color: #00b074;
  background: url(../images/ic-filter.png) 13px 12px no-repeat;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
}
.filter-wrap .filter-top .btn-filter:after {
  position: absolute;
  content: "";
  top: 15px;
  right: 12px;
  width: 14px;
  height: 8px;
  background: url(../images/ic-filter-arr.png) no-repeat;
}
.filter-wrap .filter-top .btn-filter.on:after {
  background-position: 0 bottom;
}
.search-area .inp-base {
  border-radius: 4px;
  padding-right: 40px;
  box-sizing: border-box;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.25);
}
.search-area {
  position: relative;
}
.search-area .btn-search {
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  width: 38px;
  background: url(../images/ic-search.png) 0 7px no-repeat;
  font-size: 1px;
  overflow: hidden;
  color: transparent;
}

.filter-set {
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
}
.filter-set > * {
  width: 273px;
}
.filter-set .select-box.lang {
  width: 580px;
}
.filter-set .btn-filter-search {
  height: 40px;
  background: #00b074;
  color: #fff;
}
.filter-set .btn-filter-search.setting {
  margin-top: 34px;
}

.select-box {
  font-family: "NanumSquare";
  position: relative;
}
.select-warp {
  position: relative;
}
.select-warp .btn-open {
  position: absolute;
  top: 9px;
  right: 12px;
  width: 24px;
  height: 24px;
  background: url(../images/ic-input-dropdown.png) no-repeat;
  z-index: 5;
}
.select-warp.open .btn-open {
  position: absolute;
  top: 8px;
  right: 12px;
  width: 24px;
  height: 24px;
  background: url(../images/ic-input-close.png) no-repeat;
}
/* .select-warp.open .value-box {border-color:#00b074;} */
.select-warp .ph-txt {
  border: 1px solid #dbdbdb;
  height: 38px;
  padding-left: 20px;
  line-height: 38px;
  border-radius: 4px;
}
.value-box {
  position: relative;
  display: flex;
  border: 1px solid #dbdbdb;
  height: 38px;
  padding: 0 45px 0 20px;
  border-radius: 4px;
}
.value-box .btn-all-del {
  margin-left: auto;
  margin-top: 9px;
  width: 20px;
  height: 20px;
  background: url(../images/ic-close-lp.png) no-repeat;
}
.value-box .count {
  position: absolute;
  top: 7px;
  right: 70px;
  display: block;
  width: 24px;
  height: 24px;
  border-radius: 2px;
  text-align: center;
  line-height: 22px;
  color: #fff;
  background-color: #9b9b9b;
}

.sel-value {
  overflow: hidden;
  width: 322px;
  padding-top: 7px;
  display: flex;
  flex-wrap: nowrap;
}
.sel-value span {
  display: inline-block;
  font-size: 12px;
  color: #3e3e3e;
  height: 24px;
  white-space: nowrap;
  box-sizing: border-box;
  background: #ebebeb;
  border-radius: 2px;
  padding: 5px 8px 4px;
  margin-right: 10px;
}

.sel-value {
  width: calc(100% - 50px);
  background: rgba(242, 242, 242, 0.4);
  border-radius: 2px;
  padding: 7px 0;
  box-sizing: border-box;
}
.sel-value span {
  position: relative;
}
.sel-value .btn-del {
  position: absolute;
  top: 0;
  right: 0;
  width: 24px;
  height: 24px;
  background: url(../images/ic-close-narrow.png) no-repeat center center;
}
.sel-value p {
  line-height: 24px;
  font-size: 12px;
  color: #9b9b9b;
}

.value-list {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  top: calc(100% + 7px);
  padding: 8px 0;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  background: #fff;
  z-index: 5;
}
.select-warp.open .value-list {
  display: block;
}
.value-list li {
  padding: 5px 20px;
  position: relative;
}
.value-list li:hover:before {
  position: absolute;
  content: "";
  left: -1px;
  right: -1px;
  top: 0;
  bottom: 0;
  border: 1px solid #00b074;
}
.value-list .lb-check:before {
  min-height: 13px;
  min-width: 13px;
  background: url(../images/check-box-small-blank.png) no-repeat;
}
.value-list :checked + .lb-check:before {
  background: url(../images/check-box-small.png) no-repeat;
}
.select-box.lang .value-list ul {
  display: flex;
  flex-wrap: wrap;
}
.select-box.lang .value-list li {
  width: 33.33333%;
  box-sizing: border-box;
}

.page-box {
  margin-top: 26px;
  text-align: right;
  font-size: 16px;
  line-height: 24px;
}
.page-box ul {
  display: inline-flex;
  background: #fff;
  border: 1px solid #979797;
  border-radius: 2px;
  padding: 0 5px;
}
.page-box > ul > li:nth-child(2) {
  padding: 0 30px;
}
.page-box ul select {
  width: auto;
  height: 24px;
  border: 0;
  background-color: transparent;
  padding: 0 20px 0 0;
  background-position: calc(100% + 14px) 1px;
}
.page-box ul button {
  width: 24px;
  height: 24px;
  background: #000;
}

/* 여기 고치센 */
.page-box ul .btn-first.able {
  margin-right: 5px;
  background: url(../images/ic-page-first-green.png) no-repeat;
}
.page-box ul .btn-prev.able {
  margin-right: 5px;
  background: url(../images/ic-page-prev-green.png) no-repeat;
}
.page-box ul .btn-next.able {
  margin-right: 5px;
  background: url(../images/ic-page-next-green.png) no-repeat;
}
.page-box ul .btn-last.able {
  background: url(../images/ic-page-last-green.png) no-repeat;
}

.page-box ul .btn-first.disable {
  margin-right: 5px;
  background: url(../images/ic-page-first-gray.png) no-repeat;
}
.page-box ul .btn-prev.disable {
  margin-right: 5px;
  background: url(../images/ic-page-prev-gray.png) no-repeat;
}
.page-box ul .btn-next.disable {
  margin-right: 5px;
  background: url(../images/ic-page-next-gray.png) no-repeat;
}
.page-box ul .btn-last.disable {
  background: url(../images/ic-page-last-gray.png) no-repeat;
}

.list-control {
  position: relative;
  padding: 0 0 12px 51px;
  margin-top: -14px;
}
.list-control .check {
  position: absolute;
  top: 0;
  left: 23px;
}
.list-control .check .lb-check {
  font-size: 0;
}
.list-control .check .lb-check:before {
  min-width: 28px;
  min-height: 28px;
  margin: 0;
  background: #fff;
  border: 1px solid #cbcbcb;
  border-radius: 2px;
}
.list-control .check :checked + .lb-check:before {
  border: 0;
  background: url(../images/icon-check-middle.png) no-repeat;
}
.list-control button {
  font-size: 16px;
  line-height: 28px;
  color: #3e3e3e;
}
.ctr-btns {
  font-size: 0;
}
.ctr-btns span {
  display: inline-block;
  padding: 0 20px;
  position: relative;
}
.ctr-btns span:not(:first-child):before {
  position: absolute;
  content: "";
  left: 0;
  top: 8px;
  width: 1px;
  height: 12px;
  background: #b3b3b3;
}

.inf-list {
  padding-top: 16px;
}
.inf-item {
  position: relative;
  background: #fff;
  box-sizing: border-box;
  border: 1px solid #808080;
  padding: 24px 240px;
  border-radius: 4px;
  box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.25);
}
.inf-item:not(:first-child) {
  margin-top: 30px;
}
.inf-item .check {
  position: absolute;
  top: 20px;
  left: 24px;
}
.inf-item .check .lb-check {
  font-size: 0;
}
.inf-item .lb-check:before {
  min-width: 28px;
  min-height: 28px;
  margin: 0;
  background: #fff;
  border: 1px solid #cbcbcb;
  border-radius: 2px;
}
.inf-item :checked + .lb-check:before {
  border: 0;
  background: url(../images/icon-check-middle.png) no-repeat;
}
.inf-item .profile {
  position: absolute;
  top: 14px;
  left: 85px;
}
.inf-item .profile .photo img {
  overflow: hidden;
  width: 106px;
  height: 106px;
  border-radius: 50%;
}
.inf-item .profile .photo img {
  width: 106px;
}
.inf-item .profile .use-platform {
  margin-top: 10px;
  text-align: center;
}
.inf-item .profile .use-platform span {
  width: 22px;
  height: 22px;
  border-radius: 50%;
}
.inf-item .ctr-box {
  position: absolute;
  top: 22px;
  right: 34px;
  width: 124px;
}
.inf-item .ctr-box button {
  display: block;
  width: 100%;
  box-sizing: border-box;
  font-size: 16px;
  color: #00b074;
  border: 2px solid #00b074;
  border-radius: 3px;
  height: 40px;
}
.inf-item .ctr-box button:nth-child(2) {
  margin-top: 19px;
}
.inf-item .ctr-box .ver {
  text-align: center;
  color: #00b074;
  padding-top: 6px;
}
.inf-item .title .cate {
  display: inline-block;
  height: 20px;
  border-radius: 15px;
  border: solid 2px #00b074;
  text-align: center;
  padding-top: 1px;
  font-size: 12px;
  color: #00b074;
  vertical-align: top;
  margin: 4px 10px 0 0;
  font-weight: 700;
  box-sizing: border-box;
  transform: skew(-0.03deg);
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.inf-item .title strong {
  font-family: auto;
  font-size: 21px;
  font-family: "NanumSquare";
}
.inf-item .info {
  padding-top: 10px;
  display: flex;
}
.inf-item .info .option {
  font-size: 12px;
  min-width: 200px;
  height: 20px;
  line-height: 20px;
  padding: 0 20px;
  text-align: center;
  border-radius: 10px;
  border: solid 1px #b3b3b3;
  box-sizing: border-box;
}
.inf-item .info .option span:not(:first-child):before {
  content: " / ";
}
.inf-item .info .tagbox {
  margin: 0 0 0 35px;
  line-height: 20px;
  font-size: 14px;
}
.inf-item .inf-index {
  padding-top: 21px;
}
.inf-item .inf-index ul {
  flex-wrap: nowrap;
  width: auto;
}
.inf-item .inf-index ul li {
  width: 138px;
  margin: 0;
}
.inf-item .inf-index ul li strong {
  margin: 0;
}
.inf-item .inf-index .ad-cost {
  position: absolute;
  top: 23px;
  left: 552px;
  display: inline-block;
  width: 112px;
  height: 44px;
  line-height: 44px;
  border-radius: 22px;
  text-align: center;
  background: #d8d8d8;
  color: #212529;
}

.inf-cand {
  position: relative;
  background: #fff;
  min-height: 70px;
  box-sizing: border-box;
  padding: 0 160px 0 157px;
  border: 1px solid #808080;
  border-radius: 4px;
  box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.25);
}
.inf-cand:not(:first-child) {
  margin-top: 20px;
}
.inf-cand a.profile {
  display: block;
  position: absolute;
  top: 7px;
  left: 76px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
}
.inf-cand a.profile img {
  max-width: 100%;
}
.inf-cand .check {
  position: absolute;
  top: 20px;
  left: 24px;
}
.inf-cand .check .lb-check {
  font-size: 0;
}
.inf-cand .lb-check:before {
  min-width: 28px;
  min-height: 28px;
  margin: 0;
  background: #fff;
  border: 1px solid #cbcbcb;
  border-radius: 2px;
}
.inf-cand :checked + .lb-check:before {
  border: 0;
  background: url(../images/icon-check-middle.png) no-repeat;
}
.inf-cand ul {
  display: flex;
  padding-top: 10px;
  font-size: 16px;
}
.inf-cand ul li {
  width: 202px;
}
.inf-cand ul li:first-child {
  width: 347px;
}
.inf-cand ul li:last-child {
  width: 130px;
}
.inf-cand ul li > span {
  display: block;
  padding-bottom: 6px;
}
.inf-cand ul .option {
  display: inline-block;
  font-size: 12px;
  height: 20px;
  line-height: 20px;
  padding: 0 30px;
  text-align: center;
  border-radius: 10px;
  border: solid 1px #b3b3b3;
  box-sizing: border-box;
}
.inf-cand ul .option span:not(:first-child):before {
  content: " / ";
}
.inf-cand .ctr-box {
  position: absolute;
  top: 14px;
  right: 24px;
  width: 103px;
}
.inf-cand .ctr-box button {
  display: block;
  width: 100%;
  background: #fff;
  box-sizing: border-box;
  font-size: 16px;
  color: #00b074;
  border: 2px solid #00b074;
  border-radius: 3px;
  height: 40px;
}

.inf-list.black .inf-cand {
  background: #969696;
}

.in-date {
  position: relative;
}
.in-date .btn-calendar {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  background: url(../images/ic-calendar.png) center center no-repeat;
  font-size: 1px;
  color: transparent;
  overflow: hidden;
}
.in-date .setting-btn-calendar {
  position: absolute;
  right: 0;
  width: 40px;
  height: 40px;
  background: url(../images/ic-calendar.png) center center no-repeat;
  font-size: 1px;
  color: transparent;
  overflow: hidden;
}
.filter-detail .in-date {
  width: 891px;
}

.info-update {
  text-align: right;
  margin-bottom: 24px;
}
.info-update ul {
  display: inline-flex;
  line-height: 20px;
  border: 1px solid #979797;
  background-color: rgba(216, 216, 216, 0.3);
  color: #595959;
  border-radius: 8px;
  padding: 0 12px;
}
.info-update ul span {
  display: inline-block;
  margin-left: 10px;
}
.info-update ul .date {
  margin-right: 30px;
  color: #ff0000;
}

.info-wrap .info {
  padding-top: 16px;
  display: flex;
}
.info-wrap .info .option {
  font-size: 12px;
  min-width: 200px;
  height: 20px;
  line-height: 20px;
  padding: 0 20px;
  text-align: center;
  border-radius: 10px;
  border: solid 1px #b3b3b3;
  box-sizing: border-box;
}
.info-wrap .info .option span:not(:first-child):before {
  content: " / ";
}
.info-wrap .info .tagbox {
  margin: 0 0 0 35px;
  line-height: 20px;
  font-size: 14px;
}

.influencer-info .inf-index {
  margin-left: -190px;
}
.influencer-info .inf-index ul {
  width: 100%;
}
.influencer-info .inf-index ul li {
  width: auto;
  box-sizing: border-box;
}
.influencer-info .inf-index .imp {
  padding-left: 190px;
  box-sizing: border-box;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.influencer-info .inf-index .imp li {
  font-size: 18px;
  margin-top: 10px;
}
.influencer-info .inf-index .imp li strong {
  font-size: 25px;
}
.influencer-info .inf-index ul:not(.imp) li:first-child {
  margin-left: 190px;
  width: 234px;
}
.influencer-info .inf-index ul:not(.imp) li,
.influencer-info .inf-index ul:not(.imp) li strong {
  font-size: 16px;
  transform: skew(-0.03deg);
}
.influencer-info .inf-index ul li.contact {
  width: calc(100% - 424px);
}
.influencer-info .inf-index ul li.ppl,
.influencer-info .inf-index ul li.price {
  width: 190px;
  padding-left: 20px;
}
.influencer-info .inf-index ul li.refer,
.influencer-info .inf-index ul li.mark {
  width: calc(100% - 190px);
}
.txt-box {
  margin-top: 5px;
  border: 1px solid #979797;
  font-size: 14px;
  padding: 10px;
  min-height: 100px;
}

.choice-box {
  margin-bottom: 11px;
}
.choice-box.align-right {
  overflow: hidden;
}
.choice-box.align-right .file-box {
  float: right;
}
.choice-box .mode {
  display: inline-block;
  height: 40px;
  padding: 10px 17px 0 18px;
  border: 1px solid #dbdbdb;
  background: #fff;
  box-sizing: border-box;
  vertical-align: top;
  margin-right: 12px;
}
.choice-box .mode > span:first-child {
  margin-right: 18px;
}
.choice-box .mode .lb-radio:before {
  min-width: 18px;
  min-height: 18px;
  background: none;
  border: 1px solid #979797;
  border-radius: 50%;
}
.choice-box .mode :checked + .lb-radio:before {
  border-color: #595959;
}
.choice-box .mode :checked + .lb-radio:after {
  position: absolute;
  content: "";
  height: 6px;
  width: 6px;
  top: 6px;
  left: 6px;
  border-radius: 50%;
  background: #595959;
}
.choice-box a {
  color: #757575;
  text-decoration: underline;
  font-size: 14px;
  margin-left: 12px;
}
.file-box {
  position: relative;
  display: inline-block;
  width: 440px;
  height: 40px;
  border: 1px solid #dbdbdb;
  background: #fff;
  box-sizing: border-box;
}
.file-box.disabled {
  border-color: #afafaf;
}
.file-box.disabled:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
}
.file-box .file-default {
  padding-top: 7px;
}
.file-box input[type="file"] {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.file-box label {
  color: #757575;
  display: inline-block;
  padding-left: 45px;
  line-height: 24px;
  background: url(../images/ic-file.png) 16px 0 no-repeat;
}
.file-box .in-file {
  position: relative;
  padding: 9px 0 0 20px;
}
.file-box .in-file .file-delete {
  position: absolute;
  top: 9px;
  right: 8px;
  width: 20px;
  height: 20px;
  background: url(../images/ic-close-lp.png) no-repeat;
}

.inf-insert-wrap {
  padding: 0 50px;
  display: flex;
  justify-content: space-between;
}
.inf-insert-wrap .inner-conts {
  width: calc(50% - 60px);
}
.inf-insert-wrap .form-wrap .form-box:not(:first-child) {
  margin-top: 20px;
}
.inf-insert-wrap .form-wrap .label-txt .required {
  display: inline-block;
  font-size: 16px;
}
.inf-insert-wrap .flex-box > div {
  width: calc(50% - 22px);
}
.inf-insert-wrap .lang-box {
  margin-top: 20px;
}
/* .inf-insert-wrap textarea {height:104px;}  */
.inf-insert-wrap .price-box {
  position: relative;
}
.inf-insert-wrap .price-box .btn-unit {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 40px;
  height: 40px;
  border-radius: 0;
  color: #fff;
  font-size: 16px;
  background: #00b074;
}

.content-title-box {
  background: #fff;
  border-radius: 4px;
}
.content-title-box h2 {
  font-size: 21px;
  padding: 26px 0 20px 27px;
  border-bottom: 1px solid #979797;
  font-family: "NanumSquare";
}
.content-title-box .inner {
  padding: 26px 33px 30px 33px;
}

.info-tb-wrap .title-box {
  display: flex;
  font-size: 16px;
  padding-bottom: 12px;
  font-family: "NanumSquare";
}
.info-tb-wrap .title-box span:nth-child(1) {
  width: 170px;
}
.info-tb-wrap .title-box span:nth-child(2) {
  width: 430px;
}
.info-tb-wrap .info-tb li {
  position: relative;
  display: flex;
}
.info-tb-wrap .info-tb li:not(:first-child) {
  margin-top: 17px;
}
.info-tb-wrap .info-tb .inp-txt {
  height: 30px;
  line-height: 24px;
  font-size: 12px;
}
.info-tb-wrap .info-tb li span:nth-child(1) {
  width: 150px;
  margin-right: 19px;
}
.info-tb-wrap .info-tb li span:nth-child(2) {
  width: 300px;
  margin-right: 19px;
}
.info-tb-wrap .info-tb li span:nth-child(5) {
  width: 370px;
  margin-right: 19px;
}
.info-tb-wrap .info-tb .btn-exe {
  font-size: 12px;
  height: 30px;
  padding: 0 11px;
  background: #d8d8d8;
  border-radius: 0;
  margin-right: 19px;
  font-family: "NanumSquare";
}
.info-tb-wrap .info-tb .btn-exe.on {
  background: #00b074;
  color: #fff;
}
.info-tb-wrap .info-tb .btn-del {
  position: absolute;
  top: 0;
  right: -5px;
  width: 30px;
  height: 30px;
  font-size: 1px;
  color: transparent;
  overflow: hidden;
  background: url(../images/ic-close-lp.png) center center no-repeat;
}
.info-tb-wrap .area-btns {
  margin-top: 27px;
}
.info-tb-wrap .area-btns button {
  height: 30px;
  width: 200px;
  line-height: 30px;
}

.report-top {
  position: relative;
  padding-bottom: 15px;
  /* border-bottom: 2px solid #979797; */
  margin-bottom: 34px;
}
.report-top h2 {
  padding: 0;
  border: 0;
  margin: 0;
}
.report-top .info {
  position: absolute;
  /* right: 0; */
  /* top: px; */
  top: 40px;

  font-size: 16px;
  font-family: "NanumSquare";
}
.report-top .info .platform {
  margin-right: 30px;
  color: #00b074;
}

.crw-table {
  width: 1200px;
  margin: 0 auto;
  font-size: 16px;
  text-align: center;
  font-family: "NanumSquare";
}
.crw-table table thead th {
  padding: 0 0 32px 0;
  font-size: 18px;
  color: #3e3e3e;
}
.crw-table table tbody {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.25);
  border: solid 1px #808080;
}
.crw-table table tbody td {
  padding: 21px 15px;
  border-bottom: 1px solid #979797;
}
.crw-table table tbody td:first-child {
  padding-left: 20px;
}
.crw-table table .line-del {
  width: 30px;
  height: 30px;
  font-size: 1px;
  color: transparent;
  overflow: hidden;
  background: url(../images/ic-close-lp.png) center center no-repeat;
}
.crw-table table .status {
  display: inline-block;
  width: 102px;
  height: 38px;
  line-height: 34px;
  border-radius: 19px;
  box-sizing: border-box;
}
.crw-table table .status.live {
  border: 2px solid #00b074;
  color: #00b074;
}
.crw-table table .status.stop {
  border: 2px solid #595959;
  color: #fff;
  background: #595959;
}
.crw-table table .status.end {
  border: 2px solid #595959;
  color: #595959;
}
.crw-table table tr.end {
  background: #c3c3c3;
}

.in-number {
  position: relative;
}
.in-number button {
  position: absolute;
  right: 10px;
  width: 30px;
  height: 20px;
}
.in-number .up {
  top: 0;
  background: url(../images/ic-input-updown.png) center 0 no-repeat;
}
.in-number .down {
  bottom: 0;
  background: url(../images/ic-input-dropdown.png) center bottom no-repeat;
}

.field-wrap {
  width: 1200px;
  margin: 0 auto;
  background: #fff;
  padding: 28px 35px 60px 35px;
  font-family: "NanumSquare";
}
.field-wrap .title {
  display: block;
  font-size: 16px;
  margin: 32px 0 16px 0;
  transform: skew(-0.03deg);
}
.field-wrap .title:first-child {
  margin-top: 0;
}
.field-in {
  position: relative;
  padding-left: 316px;
}
.field-in .add-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 280px;
}
.field-in .add-box .inp-txt {
  height: 44px;
}
.field-in .add-box .btn-add {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 7px;
  right: 8px;
  border: 1px solid #cbcbcb;
  border-radius: 50%;
  background: url(../images/ic-plus.png) center center no-repeat;
}
.field-group {
  border: 1px solid #dbdbdb;
  padding: 0 7px 7px 16px;
  min-height: 44px;
  box-sizing: border-box;
}
.field-group ul {
  display: flex;
  flex-wrap: wrap;
}
.field-group ul li {
  position: relative;
  min-width: 57px;
  height: 28px;
  line-height: 28px;
  font-size: 12px;
  text-align: center;
  padding: 0 12px;
  box-sizing: border-box;
  background: #ebebeb;
  margin: 7px 8px 0 0;
}
.field-group ul li .btn-del {
  position: absolute;
  top: 0;
  right: 0;
  width: 12px;
  height: 12px;
  background: url(../images/ic-close-small.png) no-repeat;
}

.mng-item {
  position: relative;
  padding: 26px 48px 32px 48px;
  margin-top: 20px;
  border-radius: 4px;
  border: solid 1px #808080;
  background: #ffffff;
}
.mng-item:first-child {
  margin-top: 40px;
}
.mng-item .title {
  border-bottom: 1px solid #cbcbcb;
  padding-bottom: 10px;
}
.mng-item .title .name {
  display: block;
  font-size: 20px;
  font-weight: 800;
}
.mng-item .title .date {
  display: inline-block;
  font-size: 14px;
  margin-top: 6px;
}
.mng-item .title .status {
  font-weight: bold;
  margin-left: 18px;
}
.mng-item .title .status.ing {
  color: #00b074;
}
.mng-item .title .status.finish {
  color: #595959;
}
.mng-item .cpagn-info {
  position: relative;
  padding: 23px 212px 0 174px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
.mng-item .cpagn-info .photo {
  position: absolute;
  top: 23px;
  left: 0;
  width: 140px;
  height: 140px;
  border-radius: 100%;
  overflow: hidden;
}
.mng-item .cpagn-info .photo img {
  max-width: 100%;
}
.mng-item .cpagn-info .info-list {
  position: relative;
  width: 745px;
  border: solid 1px #dcdcdc;
  background: #f8f8f8;
  font-size: 15px;
  color: #3e3e3e;
  font-weight: 700;
  padding: 2px 256px 26px 22px;
  box-sizing: border-box;
}
.mng-item .cpagn-info .info-list span {
  display: block;
  font-size: 12px;
  font-weight: 400;
  padding-bottom: 2px;
}
.mng-item .cpagn-info .info-list ul {
  display: flex;
  flex-wrap: wrap;
}
.mng-item .cpagn-info .info-list li {
  margin-top: 24px;
  width: 25%;
  min-width: 36px;
}
.mng-item .cpagn-info .info-list li:nth-child(6) {
  width: 75%;
}
.mng-item .cpagn-info .info-list li.signa {
  position: absolute;
  top: 2px;
  right: 22px;
  width: 235px;
}
.mng-item .cpagn-info .info-list li.signa .exep {
  padding-top: 2px;
  max-height: 75px;
  font-size: 15px;
  line-height: 13px;
  overflow-y: auto;
}
.mng-item > button {
  height: 40px;
  line-height: 40px;
  width: 160px;
  position: absolute;
  right: 49px;
}
.mng-item .btn-sub,
.mng-item .btn-cont {
  border-radius: 20px;
}
.mng-item .btn-gray {
  background: #595959;
  color: #fff;
  top: 23px;
}
.mng-item .btn-sub {
  top: 128px;
}
.mng-item .btn-cont {
  top: 198px;
}
.mng-item .btn-campaign {
  right: 220px;
}

.gray {
  background-color: rgba(219, 219, 219, 0.3);
}

.fadein {
  animation: fadein 0.5s;
  visibility: visible;
}

.fadeout {
  animation: fadeout 0.5s;
  visibility: hidden;
}

.label-text-setting {
  display: block;
  font-size: 16px;
  font-family: "NanumSquare";
  font-weight: 700;
  color: #000;
  line-height: 24px;
  padding-bottom: 10px;
  transform: skew(-0.03deg);
}

@keyframes fadein {
  from {
    opacity: 0;
    visibility: visible;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
}
