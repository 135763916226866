.inp-date .react-datepicker-popper {
  background-color: white;
  top: 4px!important;
  left: -160px!important;
  border: 1px solid #979797;
  box-shadow: 2px 2px rgba(0,0,0,0.2);
}

.inp-date .react-datepicker {
  width: 748px;
}

.inp-date .react-datepicker__month-container {
  width: 280px;
  height: 300px;
  padding: 0;
  margin-left: 60px;
}

.inp-date .react-datepicker__day, .inp-date .react-datepicker__day-name {
  width: 40px;
}

.inp-date .react-datepicker__navigation--next {
  margin-right: 14px;
  outline: 0;
}

.inp-date .react-datepicker__day--keyboard-selected {
  background-color: transparent;
}

.inp-date .react-datepicker__day--range-end {
  background-color: #00b074!important;
}